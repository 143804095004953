import { FC, useEffect, useMemo, useState } from 'react';
import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import AdminSettingsTable from './components/AdminSettingsTable';
import userAdminSettings from './hooks/userAdminSettings';
import { Checkbox, Form } from 'antd';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgSelectDepositCurrencies from 'components/UserDeposit/components/DgSelectDepositCurrencies';
import useTranslate from 'core/utils/useTranslate';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import DgImage from 'styles/UI/Image/DgImage';
import usdt from '../../../assets/images/usdt.png';
import Text from 'core/utils/Text';
import DgButton from 'styles/UI/Button/DgButton';
import DgSelectLabel from 'styles/UI/Select/DgSelectLabel';
import { useAdminSettingsUpdateQuery } from './hooks/react-query/useAdminSettingsUpdateQuery';

const AdminBonus: FC = () => {
	const { t } = useTranslate();

	const [form] = Form.useForm();

	const [selectedType, setSelectedType] = useState('');
	const [active, setActive] = useState(false);

	const { settings } = userAdminSettings();

	const { mutate: adminUpdateSetting, isLoading: isLoadingUpdateSetting } =
		useAdminSettingsUpdateQuery(() => {
			// dispatch(setHiddenModal());
		});

	const relatedSettings = useMemo(() => {
		if (!settings?.data?.length) return [];

		return settings.data.filter(
			(item) =>
				item.key === 'REGISTER_REWARD_AMOUNT' ||
				item.key === 'REGISTER_REWARD_IS_ENABLED' ||
				item.key === 'BUY_PACKAGE_REWARD_AMOUNT' ||
				item.key === 'BUY_PACKAGE_REWARD_IS_ENABLED'
		);
	}, [settings]);

	const transformedSettings = useMemo(() => {
		if (!relatedSettings?.length) return [];

		const transformedArray = relatedSettings.reduce((acc: any, curr) => {
			const label = curr.key.replace(/_(IS_ENABLED|AMOUNT)$/, ''); // Extracts the label from the key
			const existingItem = acc.find((item: any) => item.label === label);

			if (!existingItem) {
				acc.push({
					label: label,
					value: label,
					filter: label,
					itemStatusKey: '',
					itemValueKey: '',
					itemStatus: '',
					itemValue: '',
				});
			}

			return acc;
		}, []);

		transformedArray.forEach((item) => {
			const statusKey = `${item.label}_IS_ENABLED`;
			const valueKey = `${item.label}_AMOUNT`;

			item.itemStatusKey = statusKey;
			item.itemValueKey = valueKey;

			const correspondingItemStatus = relatedSettings.find((a) => a.key === statusKey);
			const correspondingItemValue = relatedSettings.find((a) => a.key === valueKey);

			if (correspondingItemStatus) {
				item.itemStatus = correspondingItemStatus.value;
				item.itemStatusCategory = correspondingItemStatus.category;
				item.itemStatusDescription = correspondingItemStatus.description;
			}

			if (correspondingItemValue) {
				item.itemValue = correspondingItemValue.value;
				item.itemValueCategory = correspondingItemValue.category;
				item.itemValueDescription = correspondingItemValue.description;
			}
		});

		return transformedArray;
	}, [relatedSettings]);

	const selectedTypeInfo = transformedSettings.find(
		(item: any) => item.value === selectedType
	);

	useEffect(() => {
		setActive(selectedTypeInfo?.itemStatus === '0' ? false : true);
		form.setFieldValue('amount', selectedTypeInfo?.itemValue || 0);
	}, [selectedTypeInfo]);

	useEffect(() => {
		if (transformedSettings?.length) {
			setSelectedType(transformedSettings[0].value);
		}
	}, [transformedSettings]);

	console.log({ transformedSettings, selectedType });

	return (
		<div className='flex justify-center items-center'>
			<div className='w-full max-w-md'>
				<DgCard>
					<DgCardHeader title='bonus' />

					<DgForm
						form={form}
						onFinish={(values) => {
							adminUpdateSetting({
								key: selectedTypeInfo?.itemStatusKey,
								value: active ? '1' : '0',
								category: selectedTypeInfo?.itemStatusCategory,
								description: selectedTypeInfo?.itemStatusDescription,
							});
							adminUpdateSetting({
								key: selectedTypeInfo?.itemValueKey,
								value: form.getFieldValue('amount'),
								category: selectedTypeInfo?.itemValueCategory,
								description: selectedTypeInfo?.itemValueDescription,
							});
						}}
					>
						<DgFormWrapper singleColumn>
							<DgFormItem>
								{/* <DgSelectDepositCurrencies label='currency' /> */}
								<DgSelectLabel
									disabled={isLoadingUpdateSetting}
									onChange={(value, option) => {
										setSelectedType(value);
									}}
									value={selectedType}
									label={'type'}
									options={transformedSettings}
								/>
							</DgFormItem>

							{!!selectedType?.length && (
								<>
									<Checkbox
										checked={active}
										onChange={(e) => {
											setActive(e.target.checked);
										}}
										className='text-black dark:text-white'
									>
										<Text tid={'enable'} />
									</Checkbox>

									<DgFormItem
										name='amount'
										rules={[
											{ required: true, message: t('amount-is-required') },
											// {
											// 	message: t('amount-should-be-greater-than-100-usdt'),
											// 	validator: (_, value) => {
											// 		try {
											// 			console.log({ value }, parseInt(value), parseInt(value) >= 100);

											// 			if (parseInt(value) >= 100) {
											// 				return Promise.resolve();
											// 			} else {
											// 				return Promise.reject('amount-should-be-greater-than-100-usdt');
											// 			}
											// 		} catch (error) {
											// 			return Promise.reject('amount-should-be-greater-than-100-usdt');
											// 		}
											// 	},
											// },
										]}
									>
										<DgInputLabel
											// value={amount}
											// onChange={onChangeInputHandler}
											label='amount'
											required
											disabled={!active}
											suffix={
												<DgImage
													src={usdt}
													width={22}
													className='rounded-full relative top-1.5'
												/>
											}
										/>
									</DgFormItem>

									<DgButton
										disabled={isLoadingUpdateSetting}
										loading={isLoadingUpdateSetting}
										title='save'
										className='mt-5 w-full'
									/>
								</>
							)}
						</DgFormWrapper>
					</DgForm>
				</DgCard>
			</div>
		</div>
	);
};

export default AdminBonus;
