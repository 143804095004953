import DgTable from 'styles/UI/Table/DgTable';
import { usePackageQuery } from '../hooks/react-query/usePackageQuery';
import AdminPackageTableColumn from './AdminPackageTableColumn';

const AdminPackageTable = ({
	onEditSettingsHandler,
	onDeletePackageHandler,
}: {
	onEditSettingsHandler?: (val: any) => void;
	onDeletePackageHandler?: (val: any) => void;
}) => {
	const { data: packages, isFetching: packageIsFetching } = usePackageQuery();

	console.log({ packages });

	return (
		<DgTable
			rowKey='id'
			columns={AdminPackageTableColumn(onEditSettingsHandler, onDeletePackageHandler)}
			dataSource={packages?.data}
			count={packages?.data.length}
			loading={packageIsFetching}
		/>
	);
};

export default AdminPackageTable;
