import Text from 'core/utils/Text';
import DgCard from 'styles/UI/Card/DgCard';
import DgRadioButton from 'styles/UI/RadioButton/DgRadioButton';
import DgRadioButtonGroup from 'styles/UI/RadioButton/DgRadioButtonGroup';
import { FC } from 'react';

interface ILanguageAndThemeDropDown {
	theme: 'dark' | 'light';
	lang: 'fa' | 'en';
	languageOnChangeHandler: (val: any) => void;
	themeOnChangeHandler: (val: any) => void;
}

const LanguageAndThemeDropDown: FC<ILanguageAndThemeDropDown> = ({
	lang,
	theme,
	languageOnChangeHandler,
	themeOnChangeHandler,
}) => {
	return (
		<DgCard isMarginBottom={false}>
			<div className='flex flex-col'>
				<div className='flex flex-col space-y-2'>
					<Text tid='theme' />
					<DgRadioButtonGroup
						defaultValue={theme}
						onChange={themeOnChangeHandler}
						optionType='button'
						buttonStyle='solid'
					>
						<DgRadioButton value='dark'>
							<Text tid='dark' />
						</DgRadioButton>
						<DgRadioButton value='light'>
							<Text tid='light' />
						</DgRadioButton>
					</DgRadioButtonGroup>
				</div>

				<hr className='mb-3 mt-5' />

				<div className='flex flex-col space-y-2'>
					<Text tid='language' />
					<DgRadioButtonGroup
						defaultValue={lang}
						onChange={languageOnChangeHandler}
						optionType='button'
						buttonStyle='solid'
					>
						<DgRadioButton value='en'>
							<Text tid='english' />
						</DgRadioButton>
						{/* <DgRadioButton value='fa'>
							<Text tid='persian' />
						</DgRadioButton> */}
					</DgRadioButtonGroup>
				</div>
			</div>
		</DgCard>
	);
};

export default LanguageAndThemeDropDown;
