import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import AdminOrderTable from './components/AdminOrderTable';
import AdminOrderFilter from './components/AdminOrderFilter';

const AdminOrder = () => {
	return (
		<DgCard>
			<DgCardHeader title='invests' />

			<AdminOrderFilter />
			<AdminOrderTable />
		</DgCard>
	);
};

export default AdminOrder;
