import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

export const loadPersistedData = (): 'fa' | 'en' => {
	if (
		window.localStorage.getItem('lang') === 'en' ||
		window.localStorage.getItem('lang') === 'fa'
	) {
		return (window.localStorage.getItem('lang') as 'fa' | 'en') || 'en';
	} else {
		window.localStorage.setItem('lang', 'en');
		return 'en';
	}
};

i18n
	.use(initReactI18next)
	.use(Backend)
	.init({
		lng: loadPersistedData(),
		fallbackLng: loadPersistedData(),
		backend: {
			loadPath: '/panel/locales/{{lng}}/{{ns}}.json',
		},
	});

export default i18n;
