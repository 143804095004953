import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgForm from 'styles/UI/Form/DgForm';
import DgButton from 'styles/UI/Button/DgButton';
import { FC, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import useMasterQueryUrlParam from 'core/hooks/useMasterQueryUrlParam';
import useRegister from '../hooks/useRegister';
import Text from 'core/utils/Text';
import useTranslate from 'core/utils/useTranslate';
import DgRadioButtonGroup from 'styles/UI/RadioButton/DgRadioButtonGroup';
import DgRadioButton from 'styles/UI/RadioButton/DgRadioButton';
import DgInputPasswordLabel from 'styles/UI/Input/DgInputPasswordLabel';
import { Checkbox } from 'antd';
import useModal from 'core/hooks/useModal';
import { RulesModal } from 'components/modals/rules-modal';
import DgAlert from 'styles/UI/Alert/DgAlert';
import CountryPhoneInput from 'antd-country-phone-input';

const Register: FC = () => {
	const [rulesAccepted, setRulesAccepted] = useState<boolean>(false);

	const {
		onFinishSignUp,
		signUpForm,
		isLoadingRegister,
		onChangeCaptcha,
		isShowCaptcha,
		genderOnChangeHandler,
	} = useRegister();

	const { setUrlParam } = useMasterQueryUrlParam();

	const { t } = useTranslate();

	const modal = useModal();

	return (
		<>
			<RulesModal useModal={modal} />

			<DgAlert
				className='bg-yellow-500 mb-4'
				message={
					<Text
						className='!text-black'
						tid="📣 Experiencing issues registering? Need help creating an account? Reach out to
					our support team for assistance. We're here to help!"
					/>
				}
			></DgAlert>

			<DgForm autoComplete='off' onFinish={onFinishSignUp} form={signUpForm}>
				<DgFormWrapper>
					<DgFormItem
						rules={[
							{
								required: true,
								message: t('first-name-is-required'),
							},
						]}
						name='first_name'
					>
						<DgInputLabel label={t('first-name')} />
					</DgFormItem>

					<DgFormItem
						rules={[
							{
								required: true,
								message: t('last-name-is-required'),
							},
						]}
						name='last_name'
					>
						<DgInputLabel label={t('last-name')} />
					</DgFormItem>

					<DgFormItem
						// rules={[
						// 	{
						// 		required: true,
						// 		message: t('username-is-required'),
						// 	},
						// ]}
						name='username'
					>
						<DgInputLabel label={t('username')} />
					</DgFormItem>

					{/* <DgFormItem
						rules={[
							{
								required: true,
								message: t('mobile-number-is-required'),
							},
						]}
						name='mobile_number'
					>
						<DgInputLabel label={t('mobile_number')} required />
					</DgFormItem> */}

					<DgFormItem
						rules={[
							{
								required: true,
								message: t('email-is-required'),
							},
							{
								type: 'email',
								message: t('email-format-incorrect'),
							},
						]}
						name='email'
					>
						<DgInputLabel autoComplete='new-password' label='email' required />
					</DgFormItem>

					<DgFormItem
						name='password'
						rules={[
							{
								required: true,
								message: t('password-is-required'),
							},
						]}
					>
						<DgInputPasswordLabel label='password' autoComplete='new-password' required />
					</DgFormItem>

					<DgFormItem
						name='password_confirmation'
						dependencies={['password']}
						rules={[
							{
								required: true,
								message: t('confirm-password-is-required'),
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value || getFieldValue('password') === value) {
										return Promise.resolve();
									}
									return Promise.reject(new Error(t('password-not-same')));
								},
							}),
						]}
					>
						<DgInputPasswordLabel label='confirm-password' required />
					</DgFormItem>

					<DgFormItem
						full
						rules={[
							{
								required: true,
								message: t('mobile-number-is-required'),
							},
						]}
						name='mobile_number'
						label={t('mobile_number')}
						tw='text-2xl'
						initialValue={{ short: 'IR' }}
					>
						<CountryPhoneInput
							dir='ltr'
							className='[&>.ant-input-wrapper>.ant-input]:!py-3'
							inline
							required
						/>
					</DgFormItem>

					<DgFormItem
						rules={
							[
								// {
								// 	required: true,
								// 	message: t('sponsor-id-is-required'),
								// },
							]
						}
						full
						name='sponsor_id'
					>
						<DgInputLabel label='sponsor_id' />
					</DgFormItem>
				</DgFormWrapper>

				<DgFormWrapper>
					<DgFormItem name='gender'>
						<DgRadioButtonGroup onChange={genderOnChangeHandler}>
							<DgRadioButton value='Male'>{t('male')}</DgRadioButton>
							<DgRadioButton value='Female'>{t('female')}</DgRadioButton>
						</DgRadioButtonGroup>
					</DgFormItem>
				</DgFormWrapper>

				<DgFormWrapper>
					{isShowCaptcha && (
						<DgFormItem>
							<ReCAPTCHA
								theme='dark'
								lang='english'
								sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ''}
								onChange={onChangeCaptcha}
							/>
						</DgFormItem>
					)}
				</DgFormWrapper>

				<Checkbox
					checked={rulesAccepted}
					onChange={(e) => {
						// console.log({ rulesAccepted, modal });

						if (!rulesAccepted) {
							modal.showModal();
						}
						setRulesAccepted(e.target.checked);
					}}
				>
					{t('i-agree-the-rules')}
				</Checkbox>

				<DgFormItem button>
					<div className='flex justify-between items-center flex-wrap'>
						<div className='flex items-center gap-3'>
							<Text tid='have-account' />

							<span
								onClick={() => setUrlParam({ page: 1 })}
								className='hover:border-b text-base border-b border-b-transparent hover:border-b-[var(--dg-primary-color)] text-[var(--dg-primary-color)] cursor-pointer'
							>
								<Text tid='login' />
							</span>
						</div>
						<DgButton
							htmlType='submit'
							isLoading={isLoadingRegister}
							disabled={isLoadingRegister || !rulesAccepted}
							title='signup'
						/>
					</div>
				</DgFormItem>
			</DgForm>
		</>
	);
};

export default Register;
