import { routes } from 'core/constants/routes';
import { BiUser } from 'react-icons/bi';
import { FiActivity } from 'react-icons/fi';
import { IoIosNotificationsOutline } from 'react-icons/io';
import { BsShieldLock } from 'react-icons/bs';
import { GiMayanPyramid } from 'react-icons/gi';
import useProfile from '../hooks/useProfile';
import ProfileSidebarLink from './ProfileSidebarLink';
import ProfileSidebarHeader from './ProfileSidebarHeader';
import Text from 'core/utils/Text';
import DgPrice from 'styles/UI/Price/DgPrice';
import { RootState } from 'core/store';
import { useSelector } from 'react-redux';
import { getMlmInfoQuery } from 'core/hooks/react-query/getMlmInfoQuery';

// sidebar links
const SIDEBAR_LINKS = [
	{
		title: 'personal',
		to: routes.PROFILE,
		icon: <BiUser size={20} />,
	},
	// {
	// 	title: 'new-member-position',
	// 	to: routes.PROFILE_MLM,
	// 	icon: <GiMayanPyramid size={20} />,
	// },
	// {
	// 	title: 'messages',
	// 	to: routes.PROFILE_NOTIFICATIONS,
	// 	icon: <IoIosNotificationsOutline size={20} />,
	// },
	{
		title: 'activity',
		to: routes.PROFILE_ACTIVITY,
		icon: <FiActivity size={20} />,
	},
	{
		title: 'security',
		to: routes.PROFILE_SECURITY,
		icon: <BsShieldLock size={20} />,
	},
];

const ProfileSidebar = () => {
	const {
		userInfo: currentUserInfo,
		avatarRef,
		isLoadingUpdateAvatar,
		isLoadingGetUser,
		onUpdateAvatarClicked,
		getDropdownStatus,
	} = useProfile();
	const { userWallet } = useSelector((store: RootState) => store.walletStore);
	const { data: mlmInfo } = getMlmInfoQuery();

	return (
		<div className='bg-[var(--dg-card-bg-1)] md:block min-h-screen flex-shrink-0 lg:w-[330px] w-[300px] ltr:border-r rtl:border-l border-[var(--dg-border-color)] -order-1 hidden'>
			<div className='sticky top-[100px]'>
				<ProfileSidebarHeader
					avatarRef={avatarRef}
					currentUserInfo={currentUserInfo}
					getDropdownStatus={getDropdownStatus}
					isLoadingUpdateAvatar={isLoadingUpdateAvatar}
					isLoadingGetUser={isLoadingGetUser}
					onUpdateAvatarClicked={onUpdateAvatarClicked}
				/>

				<div className='p-4 border-b border-[var(--dg-border-color)]'>
					<div className='flex justify-between'>
						<div className='flex flex-col'>
							<Text
								tid='my-balance'
								className='text-[var(--dg-primary-color)] mb-1 font-bold'
							/>
							<DgPrice price={userWallet?.balance} className='text-xl' />
						</div>

						{/* <div className='flex flex-col'>
							<Text tid='new-member-position' className='text-[var(--dg-primary-color)] mb-1 font-bold' />
							{mlmInfo?.data?.default_binary_position ? <Text tid={mlmInfo?.data?.default_binary_position || ''} /> : <Text tid='no-data' />}
						</div> */}
					</div>
				</div>

				<div>
					<ul className='py-3'>
						{SIDEBAR_LINKS.map((item) => (
							<li key={item.to}>
								<ProfileSidebarLink {...item} />
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default ProfileSidebar;
