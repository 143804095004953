import { useWindowSize } from 'core/hooks/useWindowSize';
import Text from 'core/utils/Text';
import DgTableColumnResponsive from 'styles/UI/TableColumnResponsive/DgTableColumnResponsive';
import DgPrice from 'styles/UI/Price/DgPrice';
import { IPackage } from 'core/types/models/packageType';
import useTranslate from 'core/utils/useTranslate';
import DgEditIcon from 'styles/UI/EditIcon/DgEditIcon';
import DgDeleteIcon from '../../../../../styles/UI/Icon/DgDeleteIcon';

const AdminPackageTableColumn = (
	onEditPackageHandler?: (val: any) => void,
	onDeletePackageHandler?: (val: any) => void
) => {
	const { width } = useWindowSize();
	const { t } = useTranslate();

	const Columns = [
		{
			title: <Text tid='name' />,
			render: (text: any, record: IPackage) => record?.name,
			sorter: false,
		},
		{
			title: <Text tid='price' />,
			key: 'price',
			dataIndex: 'price',
			render: (text: any) => <DgPrice price={text} />,
			sorter: false,
		},
		{
			title: <Text tid='direct_percentage' />,
			key: 'direct_percentage',
			dataIndex: 'direct_percentage',
			render: (text: any) => `${text}%`,
			sorter: false,
		},
		{
			title: <Text tid='roi_percentage' />,
			key: 'roi_percentage',
			dataIndex: 'roi_percentage',
			render: (text: any) => `${text}%`,
			sorter: false,
		},
		{
			title: <Text tid='expire-time' />,
			key: 'validity_in_days',
			dataIndex: 'validity_in_days',
			render: (text: any) => <DgPrice price={text} suffix={t('days')} />,
			sorter: false,
		},
		...(onEditPackageHandler
			? [
					{
						title: '',
						render: (_text: any, record: IPackage) => (
							<div className={'flex fw justify-end items-center'}>
								<DgEditIcon onClick={() => onEditPackageHandler?.(record)} />
								<DgDeleteIcon onClick={() => onDeletePackageHandler?.(record)} />
							</div>
						),
						sorter: false,
					},
			  ]
			: []),
	];

	const RColumn = [
		{
			title: '',
			sorter: false,
			render: (_text: any, record: IPackage) => {
				return (
					<>
						<DgTableColumnResponsive
							title={t('category')}
							value={<DgPrice price={record.price} />}
						/>
						<DgTableColumnResponsive title={t('price')} value={record.name} />
						<DgTableColumnResponsive
							title={t('direct_percentage')}
							value={<DgPrice price={record.direct_percentage} suffix='%' />}
						/>
						<DgTableColumnResponsive
							title={t('roi_percentage')}
							value={<DgPrice price={record.roi_percentage} suffix='%' />}
						/>
						<DgTableColumnResponsive
							title={t('expire-time')}
							value={<DgPrice price={record.validity_in_days} suffix={t('days')} />}
						/>
						{onEditPackageHandler && (
							<DgTableColumnResponsive
								title={t('operation')}
								value={
									<DgEditIcon
										className='flex justify-end'
										onClick={() => onEditPackageHandler(record)}
									/>
								}
							/>
						)}
					</>
				);
			},
		},
	];

	return width > 769 ? Columns : RColumn;
};

export default AdminPackageTableColumn;
