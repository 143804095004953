import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import DgPagination from 'styles/UI/Pagination/DgPagination';
import DgTable, { IDgTable } from 'styles/UI/Table/DgTable';
import { useUserTransactionQuery } from '../hooks/react-query/useUserTransactionQuery';
import UserTransactionTableColumn from './UserTransactionTableColumn';
import { ITransaction } from 'core/types/models/transactionType';
import { FC } from 'react';

interface IUserTransactionTable extends IDgTable {
	limit?: number;
}

const UserTransactionTable: FC<IUserTransactionTable> = ({ limit, ...props }) => {
	const { data: transactions, isFetching: transactionIsFetching } =
		useUserTransactionQuery();
	const { query, handlePagination } = useQueryUrlParam();

	let data: ITransaction[] = [];
	if (limit && transactions?.data?.list) {
		data = transactions?.data?.list.slice(0, limit);
	}

	return (
		<>
			<DgTable
				rowKey='id'
				columns={UserTransactionTableColumn()}
				dataSource={data.length > 0 ? data : transactions?.data?.list}
				count={transactions?.data?.pagination?.total}
				loading={transactionIsFetching}
				current={transactions?.data?.pagination?.current_page}
				per_page={query?.per_page}
				{...props}
			/>
			{!limit && (
				<DgPagination
					onPaginationHandler={handlePagination}
					responsive={true}
					per_page={query?.per_page}
					current={transactions?.data?.pagination?.current_page}
					total={transactions?.data?.pagination?.total}
				/>
			)}
		</>
	);
};

export default UserTransactionTable;
