import { useWindowSize } from 'core/hooks/useWindowSize';
import { IOrder } from 'core/types/models/orderType';
import Text from 'core/utils/Text';
import useTranslate from 'core/utils/useTranslate';
import React from 'react';
import DgCountDown from 'styles/UI/CountDown/DgCountDown';
import DgPrice from 'styles/UI/Price/DgPrice';
import DgTableColumnResponsive from 'styles/UI/TableColumnResponsive/DgTableColumnResponsive';
import DgTimeStamp from 'styles/UI/Timstamp/DgTimeStamp';
import DgTooltip from 'styles/UI/Tooltip/DgTooltip';

const UserOrderTableColumn = ({ showMoreColumn }: { showMoreColumn: boolean }) => {
	const { width } = useWindowSize();
	const { t } = useTranslate();

	const Columns = [
		{
			title: <Text tid='invest-package' />,
			render: (text: any, record: IOrder) => (
				<DgTooltip title={record.canceled_at ? 'Canceled' : 'Active'}>
					<span
						className={`whitespace-nowrap ${
							record.canceled_at
								? 'text-[var(--dg-red-color-2)]'
								: 'text-[var(--dg-green-color)]'
						}`}
					>
						{record.package.name}
					</span>
				</DgTooltip>
			),
			sorter: false,
		},
		{
			title: <Text tid='volume' />,
			key: 'total_cost_in_pf',
			dataIndex: 'total_cost_in_pf',
			render: (text: any) => <DgPrice price={text} />,
			sorter: false,
		},
		...(showMoreColumn
			? [
					{
						title: <Text tid='monthly-profit' />,
						render: (_text: any, record: IOrder) =>
							`${record.package.direct_percentage || ''} %`,
						sorter: false,
					},
			  ]
			: []),
		{
			title: <Text tid='invest-date' />,
			key: 'created_at',
			dataIndex: 'created_at',
			render: (text: any) => <DgTimeStamp hasTime={false} timeStamp={text} />,
			sorter: false,
		},

		{
			title: <Text tid='status' />,
			render: (text: any, record: IOrder) =>
				record.canceled_at ? (
					<span className='text-[var(--dg-red-color-2)]'>
						<DgTimeStamp timeStamp={record.canceled_at || 0} />
					</span>
				) : (
					<span className='text-[var(--dg-green-color)]'>
						<Text tid='active' />
					</span>
				),
			sorter: false,
		},
		{
			title: <Text tid='invest-release' />,
			key: 'expires_at',
			dataIndex: 'expires_at',
			render: (text: any) => <DgCountDown timeStamp={text} />,
			sorter: false,
		},
	];

	const RColumn = [
		{
			title: '',
			sorter: false,
			render: (_text: any, record: IOrder) => {
				return (
					<>
						<DgTableColumnResponsive
							title={t('invest-package')}
							value={
								<DgTooltip title={record.canceled_at ? 'Canceled' : 'Active'}>
									<span
										className={
											record.canceled_at
												? 'text-[var(--dg-red-color-2)]'
												: 'text-[var(--dg-green-color)]'
										}
									>
										{record.package.name}
									</span>
								</DgTooltip>
							}
						/>
						<DgTableColumnResponsive
							title={t('volume')}
							value={<DgPrice price={record.total_cost_in_pf} />}
						/>
						{showMoreColumn && (
							<>
								<DgTableColumnResponsive
									title={t('monthly-profit')}
									value={<DgPrice price={record.package.direct_percentage} suffix='%' />}
								/>
							</>
						)}
						<DgTableColumnResponsive
							title={t('payment-date')}
							value={<DgTimeStamp nowrap={false} timeStamp={record.is_paid_at} />}
						/>
						<DgTableColumnResponsive
							title={t('status')}
							value={
								record.canceled_at ? (
									<span className='text-[var(--dg-red-color-2)]'>
										<DgTimeStamp nowrap={false} timeStamp={record.canceled_at || 0} />
									</span>
								) : (
									<span className='text-[var(--dg-green-color)]'>
										<Text tid='active' />
									</span>
								)
							}
						/>
						<DgTableColumnResponsive
							title={t('invest-release')}
							value={<DgCountDown timeStamp={record.expires_at} />}
						/>
					</>
				);
			},
		},
	];

	return width > 400 ? Columns : RColumn;
};

export default UserOrderTableColumn;
