import axios, { AxiosInstance, AxiosRequestConfig, Cancel } from 'axios';
import Cookies from 'js-cookie';
import { ApiRequestConfig, WithAbortFn, ApiExecutor, ApiExecutorArgs, ApiError } from './api.types';
// Default config for the axios instance
const axiosParams = {
	baseURL: process.env.REACT_APP_API_BASE_URL,
	headers: {
		...(Cookies.get('refresh-jwt') || Cookies.get('jwt') ? { Authorization: `Bearer ${Cookies.get('refresh-jwt') || Cookies.get('jwt')}` } : {}),
	},
};

// Create axios instance with default params
const axiosInstance = axios.create(axiosParams);
axiosInstance.interceptors.request.use(
	function (config: AxiosRequestConfig) {
		const token = Cookies.get('refresh-jwt') || Cookies.get('jwt') ? `Bearer ${Cookies.get('refresh-jwt') || Cookies.get('jwt')}` : false;
		if (token) config.headers = { Authorization: token };
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

export const didAbort = (error: unknown): error is Cancel & { aborted: boolean } => axios.isCancel(error);

const getCancelSource = () => axios.CancelToken.source();

export const isApiError = (error: unknown): error is ApiError => {
	return axios.isAxiosError(error);
};

const withAbort = <T>(fn: WithAbortFn) => {
	const executor: ApiExecutor<T> = async (...args: ApiExecutorArgs) => {
		const originalConfig = args[args.length - 1] as ApiRequestConfig;
		// Extract abort property from the config
		const { abort, ...config } = originalConfig;

		// Create cancel token and abort method only if abort
		// function was passed
		if (typeof abort === 'function') {
			const { cancel, token } = getCancelSource();
			config.cancelToken = token;
			abort(cancel);
		}

		try {
			if (args.length > 2) {
				const [url, body] = args;
				return await fn<T>(url, body, config);
			} else {
				const [url] = args;
				return await fn<T>(url, config);
			}
		} catch (error) {
			// console.log('api error', error);
			// Add "aborted" property to the error if the request was cancelled
			if (didAbort(error)) {
				error.aborted = true;
			}

			throw error;
		}
	};

	return executor;
};

// Main api function
const api = (axios: AxiosInstance) => {
	return {
		get: <T>(url: string, config: ApiRequestConfig = {}) => withAbort<T>(axios.get)(url, config),
		delete: <T>(url: string, body: unknown, config: ApiRequestConfig = {}) => withAbort<T>(axios.delete)(url, body, config),
		post: <T>(url: string, body: unknown, config: ApiRequestConfig = {}) => withAbort<T>(axios.post)(url, body, config),
		patch: <T>(url: string, body: unknown, config: ApiRequestConfig = {}) => withAbort<T>(axios.patch)(url, body, config),
		put: <T>(url: string, body: unknown, config: ApiRequestConfig = {}) => withAbort<T>(axios.put)(url, body, config),
	};
};

export default api(axiosInstance);
