import { routes } from 'core/constants/routes';
import { mainFormatNumber } from 'core/utils/utils';
import React, { useEffect, useMemo, useState } from 'react';
import DgCard from 'styles/UI/Card/DgCard';
import DgCardHeader from 'styles/UI/CardHeader/DgCardHeader';
import DgImage from 'styles/UI/Image/DgImage';
import DgSpinnerFull from 'styles/UI/Spinner/DgSpinnerFull';
import UserChargeDepositWalletTransferForm from './UserChargeDepositWalletTransferForm';
import { useNavigate } from 'react-router-dom';
import { useAdminUserWalletsQuery } from '../../../UserWallets/hooks/useAdminUserWalletsQuery';
import { getUserInfoQuery } from 'core/hooks/react-query/getUserInfoQuery';
import DgPrice from 'styles/UI/Price/DgPrice';
import Text from 'core/utils/Text';
import DgSelectLabel from 'styles/UI/Select/DgSelectLabel';
import { useIsFetching } from '@tanstack/react-query';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import useTranslate from 'core/utils/useTranslate';
import DgInputLabel from 'styles/UI/Input/DgInputLabel';
import { Form } from 'antd';
import DgForm from 'styles/UI/Form/DgForm';
import DgButton from 'styles/UI/Button/DgButton';
import { useChangeWalletAmountMutation } from '../../hook/react-query/useChangeWalletAmountMutation';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

const UserChargeDepositWalletTransfer = () => {
	const { userDetail } = useSelector((store: RootState) => store.userInfoStore);

	const { t } = useTranslate();
	const [form] = Form.useForm();

	const { data: userWallets, isFetching: userWalletIsFetching } =
		useAdminUserWalletsQuery();
	const { isFetching: userDetailIsFetching } = getUserInfoQuery();
	const navigate = useNavigate();

	const changeAmountMutation = useChangeWalletAmountMutation();

	const options = userWallets?.data
		?.filter((item) => item.name !== 'deposit-profit-commission')
		?.map((item) => ({
			label: item.name,
			value: item.name,
			filter: item.name,
			// allItems: { ...item },
		}));

	const operations = [
		{ label: 'deposit', value: 'deposit' },
		{ label: 'withdraw', value: 'withdraw' },
	];
	const [selectedOperation, setSelectedOperation] = useState();
	const [selectedWalletType, setSelectedWalletType] = useState();

	const relatedWallet = useMemo(() => {
		if (!userWallets?.data?.length) return undefined;

		return userWallets.data.find((item) => item.name === selectedWalletType);
	}, [selectedWalletType]);

	console.log({ selectedType: selectedWalletType, relatedWallet, userDetail });

	useEffect(() => {
		if (relatedWallet?.name) {
			// form.setFieldValue('amount', relatedWallet.balance);
		}
	}, [relatedWallet]);

	return (
		<DgSpinnerFull isLoading={userDetailIsFetching || userWalletIsFetching}>
			<div className='grid lg-1300:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10 mb-10'>
				{!!userWallets?.data?.length && (
					<>
						{userWallets.data
							.filter((item) => item.name !== 'deposit-profit-commission')
							.map((item, index) => {
								return (
									<div
										key={item.name}
										className='bg-[var(--dg-card-bg-2)] h-32 rounded-lg flex justify-between items-center px-7'
									>
										<span className='ltr text-xl text-dg-white-color flex flex-col space-y-2'>
											<Text
												tid={item.name}
												className='dark:text-[var(--dg-primary-color)] text-[var(--dg-opposite-color)]'
											/>
											<DgPrice
												price={item?.balance || 0}
												className='text-[var(--dg-opposite-color)]'
											/>
										</span>

										<div>
											<DgImage
												src={require('../../../../../../../assets/images/usd.png')}
												width={75}
											/>
										</div>
									</div>
								);
							})}
					</>
				)}
			</div>

			<DgCard hasBackground={false}>
				{/* <DgCardHeader
					title='inter-user-transfer'
					tabTitle='inter-user-transfer'
					buttonText='set-transaction-password'
					onClick={() => navigate(routes.PROFILE_SECURITY)}
				/> */}
				{/* <div className='mt-14'>
					<div className='flex-1 flex items-center justify-center w-full'>
						<UserChargeDepositWalletTransferForm />
					</div>
				</div> */}

				<div className='flex flex-col items-stretch gap-4'>
					<DgSelectLabel
						label='wallet-type'
						options={options}
						loading={userWalletIsFetching}
						onChange={(value) => setSelectedWalletType(value)}
						value={selectedWalletType}
					/>

					{!!(selectedWalletType as any)?.length && (
						<>
							<DgSelectLabel
								label='operation'
								options={operations}
								loading={false}
								onChange={(value) => setSelectedOperation(value)}
								value={selectedOperation}
							/>

							{(selectedOperation as any)?.length && (
								<DgForm
									onFinish={(values) => {
										changeAmountMutation.mutate({
											amount: values.amount,
											wallet_name: selectedWalletType,
											type: selectedOperation,
											member_id: userDetail?.data?.member_id,
										});
									}}
									form={form}
								>
									<DgFormItem
										name='amount'
										rules={[{ required: true, message: t('amount-is-required') }]}
									>
										<DgInputLabel label='amount' required />
									</DgFormItem>

									<DgFormItem button>
										<DgButton title='save' className='w-full' isLoading={false} />
									</DgFormItem>
								</DgForm>
							)}
						</>
					)}
				</div>
			</DgCard>
		</DgSpinnerFull>
	);
};

export default UserChargeDepositWalletTransfer;
