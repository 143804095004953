import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import DgPagination from 'styles/UI/Pagination/DgPagination';
import DgTable from 'styles/UI/Table/DgTable';
import { useAdminPlanCommissionsQuery } from '../hooks/react-query/useAdminPlanCommissionsQuery';
import AdminPlanCommissionsTableColumn from './AdminPlanCommissionsTableColumn';

const AdminPlanCommissionsTable = () => {
	const { data: planCommissions, isFetching: planCommissionsIsFetching } =
		useAdminPlanCommissionsQuery();
	const { query, handlePagination } = useQueryUrlParam();

	return (
		<>
			<DgTable
				rowKey='id'
				columns={AdminPlanCommissionsTableColumn()}
				dataSource={planCommissions?.data?.list}
				count={planCommissions?.data?.pagination?.total}
				loading={planCommissionsIsFetching}
				current={planCommissions?.data?.pagination?.current_page}
				per_page={query?.per_page}
			/>
			<DgPagination
				onPaginationHandler={handlePagination}
				responsive={true}
				per_page={query?.per_page}
				current={planCommissions?.data?.pagination?.current_page}
				total={planCommissions?.data?.pagination?.total}
			/>
		</>
	);
};

export default AdminPlanCommissionsTable;
