import { Tree, TreeNode } from 'react-organizational-chart';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import { Avatar, Button, Card, Spin, Typography } from 'antd';
import { useMemo } from 'react';
import { FiCornerLeftDown, FiCornerRightDown, FiUsers } from 'react-icons/fi';
import useACL from 'core/services/role/useACL';
import useModal from 'core/hooks/useModal';
import DgModal from 'styles/UI/Modal/DgModal';
import { RxDoubleArrowDown } from 'react-icons/rx';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import DgPagination from 'styles/UI/Pagination/DgPagination';
import DgTable from 'styles/UI/Table/DgTable';
import Text from 'core/utils/Text';
import DgPrice from 'styles/UI/Price/DgPrice';
import { IPlanCommission } from 'core/types/models/planCommissionsType';
import DgTooltip from 'styles/UI/Tooltip/DgTooltip';
import { convertTimeStampToDate } from 'core/utils/utils';
import DgCountDown from 'styles/UI/CountDown/DgCountDown';
import useTranslate from 'core/utils/useTranslate';

const useTreeData = ({ key = 'root', disabled = false, query = {}, level = 4 } = {}) => {
	return useQueryHook(
		[endPointUrls.GET_REFERRAL_TREE + key],
		() => api.get(endPointUrls.GET_REFERRAL_TREE(), { params: { level, ...query } }),
		{
			enabled: !disabled,
			select: (response: any) => response?.data?.data,
			retryOnMount: false,
			refetchOnMount: false,
		}
	);
};

const useCommissions = ({ key = 'commissions', disabled = false, query = {} } = {}) => {
	return useQueryHook(
		[key],
		() => api.get(endPointUrls.MLM_GET_COMMISSIONS(), { params: { ...query } }),
		{
			enabled: !disabled,
			select: (response: any) => response?.data?.data,
			retryOnMount: false,
			refetchOnMount: false,
		}
	);
};
const useAdminCommissions = ({
	key = 'admin-commissions',
	disabled = false,
	query = {},
} = {}) => {
	return useQueryHook(
		[key],
		() => api.get(endPointUrls.ADMIN_USER_COMMISSIONS(), { params: { ...query } }),
		{
			enabled: !disabled,
			select: (response: any) => response?.data?.data,
			retryOnMount: false,
			refetchOnMount: false,
		}
	);
};

const useChildren = (data: any) => {
	const children = useMemo(() => {
		const children = data?.children?.length ? [...data.children] : [];

		if (children.length === 1 && data?.id) {
			children.push({});
		}

		children.sort((a, b) => {
			return a?.position === 'right' || b?.position === 'left' ? 1 : -1;
		});

		return children;
	}, [data]);

	return {
		children,
	};
};

export const BinaryTree = () => {
	return (
		<div
			// TODO: refactor styles
			style={{
				backgroundColor: '#ffffff',
				backgroundImage:
					'radial-gradient(#000000 0.4px, transparent 0.4px), radial-gradient(#000000 0.4px, #ffffff 0.4px)',
				backgroundSize: '16px 16px',
				backgroundPosition: '0 0,8px 8px',
			}}
			className='overflow-hidden h-screen'
			dir='ltr'
		>
			<div className='text-center py-2'>
				<Text tid='tree-desc' className='text-black' />
			</div>
			<TransformWrapper
				wheel={{ step: 0.05, wheelDisabled: true }}
				pinch={{ step: 0.05 }}
				doubleClick={{ disabled: true }}
				initialScale={0.75}
				limitToBounds={false}
				centerZoomedOut={false}
				minScale={0.1}
			>
				<TransformComponent>
					<RootNode />
				</TransformComponent>
			</TransformWrapper>
		</div>
	);
};

const RootNode = () => {
	const treeData = useTreeData();

	const { data = {}, isFetching } = treeData;

	const { children } = useChildren(data);

	return (
		<>
			{isFetching && !children?.length ? (
				<div className='flex items-center justify-center p-12'>
					<Spin />
				</div>
			) : (
				<>
					<Tree lineWidth={'2px'} label={<NodeCard data={treeData} />}>
						{!!children?.length && (
							<>
								{children.map((item, index) => (
									<ChildNode key={item.id || index} data={item} />
								))}
							</>
						)}
					</Tree>
				</>
			)}
		</>
	);
};

const ChildNode = ({ data = {} }: { data: any }) => {
	let treeData: any = useTreeData({
		disabled: true,
		key:
			data?.user?.id?.toString() +
			data.position?.toString() +
			data.created_at?.toString(),
		query: { id: data?.user?.id },
	});

	// const finalData = useMemo(() => {
	// 	const tempData = { ...treeData };

	// 	if (!tempData?.data?.id) {
	// 		tempData.data =  data;
	// 	}

	// 	return tempData;
	// }, [treeData, data]);

	// const { children } = useChildren(finalData);

	treeData = { ...treeData };

	if (!treeData?.data?.id) {
		treeData.data = data;
	}

	const { data: refetchedData = {} } = treeData;

	const displayData = refetchedData?.id ? refetchedData : data;

	const { children } = useChildren(displayData);

	return (
		// <TreeNode label={<NodeCard data={finalData} />}>
		<TreeNode label={<NodeCard data={treeData} />}>
			{!!children?.length && (
				<>
					{children.map((item, index) => (
						<ChildNode key={item.id || index} data={item} />
					))}
				</>
			)}
		</TreeNode>
	);
};

const NodeCard = ({ data = {} }: { data: any }) => {
	const {
		data: {
			avatar,
			id,
			has_children,
			user = {},
			children = [],
			position,
			left_package_price,
			right_package_price,
			children_count_left,
			children_count_right,
			highest_package_detail,
			highest_package,
		} = {} as any,
		refetch = () => {},
		isLoading,
		isFetching,
		isFetched,
	} = data;

	const { currentUserInfo: { data: profile } = {} } = useSelector(
		(store: RootState) => store.userInfoStore
	);

	const { modal, showModal, hideModal } = useModal();
	const { roles, hasAccessTo } = useACL();
	const { query, handlePagination } = useQueryUrlParam();

	const isSelf = profile?.id === user?.id;
	const isSuperAdmin = hasAccessTo(roles.superAdmin);
	const { t } = useTranslate();

	const commissions = useCommissions({ key: id, disabled: isSuperAdmin ? true : !modal });
	const adminCommissions = useAdminCommissions({
		key: id,
		disabled: isSuperAdmin ? !modal : true,
		query: {
			user_id: user?.id,
		},
	});

	const commissionsData: any = isSuperAdmin ? adminCommissions : commissions;

	const columns = [
		{
			title: <Text tid='profit-amount' />,
			key: 'amount',
			dataIndex: 'amount',
			render: (text: any) => <DgPrice price={text} />,
		},
		{
			title: <Text tid='type' />,
			key: 'type',
			dataIndex: 'type',
			render: (text: any) => <span className='whitespace-nowrap'>{text}</span>,
		},
		{
			title: <Text tid='invest-package' />,
			render: (_text: any, record: IPlanCommission) => {
				const { dateTime } = convertTimeStampToDate(
					record?.orderedPackage?.order?.canceled_at || 0
				);
				return (
					<DgTooltip
						overlayStyle={{ maxWidth: '300px' }}
						title={
							record.orderedPackage?.order?.canceled_at
								? t('package-canceled') + `${dateTime}`
								: t('package-active')
						}
					>
						<span
							className={`whitespace-nowrap ${
								record.orderedPackage?.order?.canceled_at
									? 'text-[var(--dg-red-color-2)]'
									: 'text-[var(--dg-green-color)]'
							}`}
						>
							{record.orderedPackage?.order?.package.name}
						</span>
					</DgTooltip>
				);
			},
		},
		{
			title: <Text tid='volume' />,
			render: (_text: any, record: IPlanCommission) => (
				<DgPrice price={record.orderedPackage?.order?.total_cost_in_pf} />
			),
		},
		// {
		// 	title: <Text tid='type' />,
		// 	render: (_text: any, record: IPlanCommission) => <Text tid={record.type} />,
		// },
		{
			title: <Text tid='subscription' />,
			render: (_text: any, record: IPlanCommission) => (
				<DgPrice price={record.orderedPackage?.order?.registration_fee_in_pf} />
			),
		},
		{
			title: <Text tid='invest-total-cost' />,
			render: (_text: any, record: IPlanCommission) => (
				<DgPrice price={record.orderedPackage?.order?.total_cost_in_pf} />
			),
		},
		{
			title: <Text tid='invest-release' />,
			render: (_text: any, record: IPlanCommission) => (
				<DgCountDown timeStamp={record.orderedPackage?.order?.expires_at} />
			),
		},
	];

	return (
		<>
			<div className='mx-auto flex items-center justify-center'>
				{!!id ? (
					<Card
						title={
							<div className='flex flex-col items-center gap-2'>
								<Avatar size={75} src={avatar} />

								<Typography>
									{user?.full_name || user?.email} ( {position === 'left' ? 'L' : 'R'} )
								</Typography>
							</div>
						}
						className='mx-6 min-w-[260px] dark:bg-dg-card-bg-1 bg-[var(--dg-primary-color-light-hover-3)]'
					>
						{isSelf && (
							<div className='flex items-center gap-4 border-b pb-2 mb-4'>
								<Text
									tid='referral-code'
									className='text-[var(--dg-primary-color)] font-bold'
								/>
								<div className='flex flex-col items-start'>
									<span>{profile?.sponsor || ''}</span>
									<span>{profile?.sponsor_id || ''}</span>
								</div>
							</div>
						)}
						<div className='flex flex-col gap-2'>
							<div className='flex items-center justify-between'>
								<Typography className='flex flex-col items-start justify-center gap-1'>
									<DgPrice
										price={parseFloat(left_package_price)}
										className={
											left_package_price === 0 || right_package_price === 0
												? ''
												: left_package_price < right_package_price
												? 'text-[var(--dg-green-color)]'
												: ''
										}
									/>
									{/* <Badge overflowCount={9999} count={left_package_price} showZero={true} style={{ backgroundColor: '#52c41a' }} /> */}
									{/* <GiFallingStar size='2rem' style={{ transform: 'rotateY(180deg)' }} /> */}

									<span className='whitespace-nowrap flex items-center gap-1 mt-3'>
										<FiCornerLeftDown size='1.5rem' /> {children_count_left}{' '}
										<FiUsers size={20} />
									</span>
								</Typography>

								<Typography className='flex flex-col items-end justify-center gap-1'>
									<DgPrice
										price={parseFloat(right_package_price)}
										className={
											left_package_price === 0 || right_package_price === 0
												? ''
												: left_package_price > right_package_price
												? 'text-[var(--dg-green-color)]'
												: ''
										}
									/>
									{/* <Badge overflowCount={9999} count={right_package_price} showZero={true} style={{ backgroundColor: '#52c41a' }} /> */}
									{/* <GiFallingStar size='2rem' /> */}

									<span className='whitespace-nowrap flex items-center gap-1 mt-3'>
										{children_count_right} <FiUsers size={20} />
										<FiCornerRightDown size='1.5rem' />
									</span>
								</Typography>
							</div>
							<div className='flex items-center justify-center gap-2'>
								{/* {(isSelf || isSuperAdmin) && (
									<Button icon={<AiOutlineDollarCircle size={22} className='inline-block' />} shape='circle' onClick={showModal}>
										<AiOutlineDollarCircle size={22} className='inline-block' />
									</Button>
								)} */}

								{!!has_children && (
									<Button
										icon={<RxDoubleArrowDown size={22} className='inline-block' />}
										shape='circle'
										loading={isFetching}
										disabled={isFetching}
										onClick={() => {
											refetch();
										}}
									>
										{/* {isFetched || children?.length ? <TfiReload size={22} className='inline-block' /> : <FiMoreHorizontal size={22} className='inline-block' />} */}
									</Button>
								)}
							</div>
						</div>
					</Card>
				) : (
					<Card className='mx-6 min-w-[220px] dark:bg-dg-card-bg-1 bg-[var(--dg-primary-color-light-hover-3)]'>
						<div className='flex flex-col items-center '>
							<Typography>Empty</Typography>
						</div>
					</Card>
				)}
			</div>

			{!!modal && id && (
				<DgModal
					title={'last-received-commissions'}
					open={modal}
					onCancel={hideModal}
					width={800}
				>
					{!!commissionsData?.data?.list?.length && (
						<>
							<DgTable
								rowKey='id'
								columns={columns}
								dataSource={commissionsData?.data?.list}
								count={commissionsData?.data?.pagination?.total}
								loading={commissionsData?.isFetching}
								current={commissionsData?.data?.pagination?.current_page}
								per_page={query?.per_page}
							/>
							<DgPagination
								onPaginationHandler={handlePagination}
								responsive={true}
								per_page={query?.per_page}
								current={commissionsData?.data?.pagination?.current_page}
								total={commissionsData?.data?.pagination?.total}
							/>
						</>
					)}
				</DgModal>
			)}
		</>
	);
};
