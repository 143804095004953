import { InputRef } from 'antd';
import { useEffect, useRef } from 'react';
import { HiArrowSmLeft } from 'react-icons/hi';
import DgButton from 'styles/UI/Button/DgButton';
import DgInput from 'styles/UI/Input/DgInput';
import style from './forgetPasswordOtp.module.scss';
import useForgetPasswordOtp from '../hooks/useForgetPasswordOtp';
import DgForm from 'styles/UI/Form/DgForm';
import DgFormWrapper from 'styles/UI/FormWrapper/DgFormWrapper';
import DgFormItem from 'styles/UI/FormItem/DgFormItem';
import ReCAPTCHA from 'react-google-recaptcha';
import DgInputPasswordLabel from 'styles/UI/Input/DgInputPasswordLabel';

const ForgetPasswordOtp = () => {
	const {
		code1,
		code2,
		code3,
		code4,
		code5,
		code6,
		t,
		focusHandler,
		onKeyDown,
		passChangeHandler,
		resend,
		resendHandler,
		seconds,
		query,
		forgetPasswordOtpForm,
		goBackToLoginForm,
		onFinishForgetPasswordHandler,
		isLoadingRegisterVerify,
		isShowCaptcha,
		onChangeCaptcha,
	} = useForgetPasswordOtp();

	const textInput = useRef<InputRef>(null);

	useEffect(() => {
		let isCancelled = false;
		if (!isCancelled && textInput.current) {
			textInput.current.focus();
		}
		return () => {
			isCancelled = true;
		};
	}, [textInput]);

	return (
		<>
			<div className='flex gap-x-5 items-center mb-5'>
				<HiArrowSmLeft className='cursor-pointer' size={35} onClick={goBackToLoginForm} />
			</div>

			<DgForm form={forgetPasswordOtpForm} onFinish={onFinishForgetPasswordHandler}>
				<DgFormWrapper>
					<DgFormItem
						name='password'
						rules={[
							{
								required: true,
								message: t('password-is-required'),
							},
						]}
					>
						<DgInputPasswordLabel label='password' autoComplete='new-password' required />
					</DgFormItem>

					<DgFormItem
						name='password_confirmation'
						dependencies={['password']}
						rules={[
							{
								required: true,
								message: t('confirm-password-is-required'),
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value || getFieldValue('password') === value) {
										return Promise.resolve();
									}
									return Promise.reject(new Error(t('password-not-same')));
								},
							}),
						]}
					>
						<DgInputPasswordLabel label='confirm-password' required />
					</DgFormItem>
				</DgFormWrapper>

				<div className={style.mobile}>{`${t('enter-email-one-time-password')} ${
					query?.email || ''
				}`}</div>

				<div className={style.formContainer}>
					<div className='flex gap-x-2' dir='ltr'>
						<DgInput
							tabIndex={1}
							maxLength={1}
							className='pb-[10px] text-center'
							onChange={(e) => passChangeHandler(e, 'code1')}
							onInput={focusHandler}
							value={code1}
							type='text'
							ref={textInput}
						/>
						<DgInput
							tabIndex={2}
							maxLength={1}
							className='pb-[10px] text-center'
							onChange={(e) => passChangeHandler(e, 'code2')}
							onInput={focusHandler}
							onKeyDown={onKeyDown}
							value={code2}
							type='text'
						/>
						<DgInput
							tabIndex={3}
							maxLength={1}
							className='pb-[10px] text-center'
							onChange={(e) => passChangeHandler(e, 'code3')}
							onInput={focusHandler}
							onKeyDown={onKeyDown}
							value={code3}
							type='text'
						/>
						<DgInput
							tabIndex={4}
							maxLength={1}
							className='pb-[10px] text-center'
							onChange={(e) => passChangeHandler(e, 'code4')}
							onInput={focusHandler}
							onKeyDown={onKeyDown}
							value={code4}
							type='text'
						/>
						<DgInput
							tabIndex={5}
							maxLength={1}
							className='pb-[10px] text-center'
							onChange={(e) => passChangeHandler(e, 'code5')}
							onInput={focusHandler}
							onKeyDown={onKeyDown}
							value={code5}
							type='text'
						/>
						<DgInput
							tabIndex={6}
							maxLength={1}
							className='pb-[10px] text-center'
							onChange={(e) => passChangeHandler(e, 'code6')}
							onKeyDown={onKeyDown}
							value={code6}
							type='text'
						/>
					</div>
				</div>

				<DgFormWrapper>
					{isShowCaptcha && (
						<DgFormItem>
							<ReCAPTCHA
								theme='dark'
								lang='english'
								sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ''}
								onChange={onChangeCaptcha}
							/>
						</DgFormItem>
					)}
				</DgFormWrapper>

				<div className={style.action}>
					<div className={style.timerContainer}>
						{resend ? (
							<button className={style.resendBtn} onClick={resendHandler}>
								{t('resend')}
							</button>
						) : (
							<span>{`${t('resend-in')} 00:${seconds}`}</span>
						)}
					</div>

					<DgButton
						isLoading={isLoadingRegisterVerify}
						htmlType='submit'
						title='submit'
					/>
				</div>
			</DgForm>
		</>
	);
};

export default ForgetPasswordOtp;
