import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import DgPagination from 'styles/UI/Pagination/DgPagination';
import DgTable from 'styles/UI/Table/DgTable';
import { useAdminUserQuery } from '../hooks/react-query/useAdminUserQuery';
import AdminUserTableColumn from './AdminUserTableColumn';

const AdminUsersTable = () => {
	const { data: users, isFetching: userIsFetching } = useAdminUserQuery();
	const { query, handlePagination } = useQueryUrlParam();

	console.log({ users });

	return (
		<>
			<DgTable
				rowKey='id'
				columns={AdminUserTableColumn()}
				dataSource={users?.data?.list}
				count={users?.data?.pagination?.total}
				loading={userIsFetching}
				current={users?.data?.pagination?.current_page}
				per_page={query?.per_page}
			/>

			<DgPagination
				onPaginationHandler={handlePagination}
				responsive={true}
				per_page={query?.per_page}
				current={users?.data?.pagination?.current_page}
				total={users?.data?.pagination?.total}
			/>
		</>
	);
};

export default AdminUsersTable;
