import { useWindowSize } from 'core/hooks/useWindowSize';
import Text from 'core/utils/Text';
import DgTableColumnResponsive from 'styles/UI/TableColumnResponsive/DgTableColumnResponsive';
import DgPrice from 'styles/UI/Price/DgPrice';
import { IAdminOrder } from 'core/types/models/orderType';
import DgTimeStamp from 'styles/UI/Timstamp/DgTimeStamp';
import DgCountDown from 'styles/UI/CountDown/DgCountDown';
import DgTooltip from 'styles/UI/Tooltip/DgTooltip';
import useTranslate from 'core/utils/useTranslate';
import { Link } from 'react-router-dom';
import { routes } from 'core/constants/routes';

const AdminOrderTableColumn = () => {
	const { width } = useWindowSize();
	const { t } = useTranslate();

	const Columns = [
		{
			title: <Text tid='full-name(referral-code)' />,
			render: (text: any, record: any) => (
				<>
					{/* <span className='whitespace-nowrap'>
						{record?.user_full_name} ({record?.user_member_id})
					</span> */}

					<Link
						to={routes.ADMIN_USER_Detail.link(record.user?.id)}
						className='flex items-center gap-3 group'
					>
						<Text
							tid={
								`${record?.user_full_name} (${record?.user_member_id})` ||
								record.user?.email ||
								record.user?.username
							}
						/>
					</Link>
				</>
			),
			sorter: false,
		},
		{
			title: <Text tid='parent' />,
			render: (text: any, record: IAdminOrder) =>
				record.parent && (
					<span className='whitespace-nowrap'>
						{record.parent.first_name} {record.parent.last_name} (
						{record.parent.member_id})
					</span>
				),
			sorter: false,
		},
		{
			title: <Text tid='type' />,
			render: (text: any, record: IAdminOrder) => (
				<DgTooltip title={record.canceled_at ? 'Canceled' : 'Active'}>
					<span
						className={`whitespace-nowrap ${
							record.canceled_at
								? 'text-[var(--dg-red-color-2)]'
								: 'text-[var(--dg-green-color)]'
						}`}
					>
						{record.package.category.name}
					</span>
				</DgTooltip>
			),
			sorter: false,
		},
		{
			title: <Text tid='amount' />,
			key: 'packages_cost_in_pf',
			dataIndex: 'packages_cost_in_pf',
			render: (text: any, record: IAdminOrder) => (
				<DgPrice price={record.package_cost_in_pf} />
			),
		},
		{
			title: <Text tid='total' />,
			key: 'total_cost_in_pf',
			dataIndex: 'total_cost_in_pf',
			render: (text: any) => <DgPrice price={text} />,
			sorter: false,
		},
		{
			title: <Text tid='package-name' />,
			render: (text: any, record: IAdminOrder) => record.package.name,
			sorter: false,
		},
		{
			title: <Text tid='status' />,
			render: (text: any, record: IAdminOrder) =>
				record.canceled_at ? (
					<span className='text-[var(--dg-red-color-2)]'>
						<Text tid='deactive' />
					</span>
				) : (
					<span className='text-[var(--dg-green-color)]'>
						<Text tid='active' />
					</span>
				),
			sorter: false,
		},
		{
			title: <Text tid='payment-date' />,
			key: 'created_at',
			dataIndex: 'created_at',
			render: (text: any) => <DgTimeStamp timeStamp={text} />,
		},
		{
			title: <Text tid='invest-release' />,
			key: 'expires_at',
			dataIndex: 'expires_at',
			render: (text: any) => <DgCountDown timeStamp={text} />,
			sorter: false,
		},
	];

	const RColumn = [
		{
			sorter: false,
			title: '',
			render: (_text: any, record: any) => {
				return (
					<>
						<DgTableColumnResponsive
							title={t('full-name(referral-code)')}
							value={
								<Link
									to={routes.ADMIN_USER_Detail.link(record.user?.id)}
									className='flex items-center gap-3 group'
								>
									<Text
										tid={
											`${record?.user_full_name} (${record?.user_member_id})` ||
											record.user?.email ||
											record.user?.username
										}
									/>
								</Link>
							}
						/>
						<DgTableColumnResponsive
							title={t('parent')}
							value={
								record.parent && (
									<span className='whitespace-nowrap'>
										{record.parent.first_name} {record.parent.last_name} (
										{record.parent.member_id})
									</span>
								)
							}
						/>

						<DgTableColumnResponsive
							title={t('type')}
							value={
								<DgTooltip title={record.canceled_at ? 'Canceled' : 'Active'}>
									<span
										className={
											record.canceled_at
												? 'text-[var(--dg-red-color-2)]'
												: 'text-[var(--dg-green-color)]'
										}
									>
										{record.package.category.name}
									</span>
								</DgTooltip>
							}
						/>

						<DgTableColumnResponsive
							title={t('amount')}
							value={<DgPrice price={record.package_cost_in_pf} />}
						/>

						<DgTableColumnResponsive
							title={t('total')}
							value={<DgPrice price={record.total_cost_in_pf} />}
						/>
						<DgTableColumnResponsive
							title={t('package-name')}
							value={record.package.name}
						/>
						<DgTableColumnResponsive
							title={t('status')}
							value={
								record.canceled_at ? (
									<span className='text-[var(--dg-red-color-2)]'>
										<Text tid='deactive' />
									</span>
								) : (
									<span className='text-[var(--dg-green-color)]'>
										<Text tid='active' />
									</span>
								)
							}
						/>
						<DgTableColumnResponsive
							title={t('payment-date')}
							value={<DgTimeStamp timeStamp={record.created_at} />}
						/>
						<DgTableColumnResponsive
							title={t('invest-release')}
							value={<DgCountDown timeStamp={record.expires_at} />}
						/>
					</>
				);
			},
		},
	];

	return width > 769 ? Columns : RColumn;
};

export default AdminOrderTableColumn;
