import CrispProvider from 'components/layouts/crisp/components/CrispProvider';
import GlobalLoading from 'components/layouts/GlobalLoading';
import PrivateLayout from 'components/layouts/PrivateLayout';
import PublicLayout from 'components/layouts/PublicLayout';
import PrivateRoute from 'components/Route/PrivateRoute';
import { routes } from 'core/constants/routes';
import { lazy, memo, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { RootState } from 'core/store';
import ProfileLayout from 'components/layouts/ProfileLayout';
import useACL from 'core/services/role/useACL';
import UserDepositWalletTransfer from 'components/UserWallets/components/Transfer/UserDepositWalletTransfer';
import { getUserRoleQuery } from 'core/hooks/react-query/getUserRoleQuery';
import SocketProvider from 'core/providers/socket/SocketProvider';
import { ScaleLoader } from 'react-spinners';
import UserInternalTransferPageList from './UserInternalTransfer/UserInternalTransferPageList';
import LoginRegisterPage from './LoginRegister/LoginRegisterPage';
import HijackPage from './Hijack/HijackPage';
import MainUserDashboard from './Dashboard/MainUserDashboard';
import AdminUserPageDetail from './Admin/User/Detail/AdminUserPageDetail';
import AdminUsersPageList from './Admin/User/List/AdminUsersPageList';
import AdminRolePageList from './Admin/Role/List/AdminRolePageList';
import UsersActivityListPage from './Admin/UsersActivity/List/UsersActivityListPage';
import AdminSettingsListPage from './Admin/Settings/List/AdminSettingsListPage';
import ProfilePage from './Profile/ProfilePage';
import ProfileActivityPage from './Profile/ProfileActivityPage';
import ProfileSecurityPage from './Profile/ProfileSecurityPage';
import ProfileNotificationPage from './Profile/ProfileNotificationPage';
import AdminPackagePageList from './Admin/Package/List/AdminPackagePageList';
import UserWalletsPage from './UserWallets/UserWalletsPage';
import UserTransactionPage from './UserTransaction/UserTransactionPage';
import OrderSubmitPage from './OrderSubmit/OrderSubmitPage';
import UserActivePlansPageList from './UserActivePlans/UserActivePlansPageList';
import UserTransferPageList from './UserTransfer/UserTransferPageList';
import ProfileMlmSettingPage from './Profile/ProfileMlmSettingPage';
import BinaryTreePage from './BinaryTree/BinaryTreePage';
import AdminOrderPageList from './Admin/Order/List/AdminOrderPageList';
import UserGoogleAuthPageList from './UserGoogleAuth/UserGoogleAuthPageList';
import UserPlansHistoryPageList from './UserPlansHistory/UserPlansHistoryPageList';
import UserPlanCommissionsPageList from './UserPlanCommissions/List/UserPlanCommissionsPageList';
import AdminPlanCommissionsPageList from './Admin/PlanCommissions/List/AdminPlanCommissionsPageList';
import AdminUserTransactionPage from './Admin/UserTransaction/AdminUserTransactionPage';
import UserDepositPage from './UserDeposit/UserDepositPage';
import UserWithdrawPage from './UserWithdraw/UserWithdrawPage';
import AdminInvoicePage from './Admin/Invoice/AdminInvoicePage';
import AdminWithdraws from './Admin/Withdraws/AdminWithdraws.jsx';
import UserWithdrawList from './UserWithdrawList/UserWithdrawList.jsx';
import AdminAccounting from './Admin/Accounting/AdminAccounting';
import AdminBannerPageList from './Admin/Banner/List/AdminBannerPageList';
import AdminWalletSettingsListPage from './Admin/WalletSettings/List/AdminWalletSettingsListPage';
import AdminBonusPage from './Admin/Bonus/AdminBonus';
import Accounting from './Accounting/Accounting';
import AdminBalanceProfitPage from './Admin/BalanceProfit/AdminBalanceProfit';
// import AdminAccounting from './Admin/UserTransaction/AdminUserTransactionPage';

const MainRouter = () => {
	// store
	const { isAuthenticated } = useSelector((store: RootState) => store.mainInfoStore);

	// hooks
	const { hasAccessTo, roles } = useACL();

	// query
	const { isFetching: userRolesIsFetching } = getUserRoleQuery();

	const privateRouteLists = [
		{ path: routes.DASHBOARD, element: <MainUserDashboard />, role: true },
		{ path: routes.BINARY_TREE, element: <BinaryTreePage />, role: true },
		{
			path: routes.PROFILE,
			element: (
				<ProfileLayout>
					<ProfilePage />
				</ProfileLayout>
			),
			role: true,
		},
		{
			path: routes.PROFILE_ACTIVITY,
			element: (
				<ProfileLayout>
					<ProfileActivityPage />
				</ProfileLayout>
			),
			role: true,
		},
		{
			path: routes.PROFILE_SECURITY,
			element: (
				<ProfileLayout>
					<ProfileSecurityPage />
				</ProfileLayout>
			),
			role: true,
		},
		{
			path: routes.PROFILE_SECURITY_ACTIVE_2FA,
			element: <UserGoogleAuthPageList />,
			role: true,
		},
		{
			path: routes.PROFILE_MLM,
			element: (
				<ProfileLayout>
					<ProfileMlmSettingPage />
				</ProfileLayout>
			),
			role: true,
		},
		{
			path: routes.NOTIFICATIONS,
			element: (
				<>
					{/* <ProfileLayout> */}
					<ProfileNotificationPage />
					{/* </ProfileLayout> */}
				</>
			),
			role: true,
		},
		{
			path: routes.HIJACK.route,
			element: <HijackPage />,
			role: hasAccessTo(roles.user?.admin),
		},
		{
			path: routes.ADMIN_USER,
			element: <AdminUsersPageList />,
			role: hasAccessTo(roles.user?.admin),
		},
		{
			path: routes.ADMIN_USER_Detail_PAGE.route,
			element: <AdminUserPageDetail />,
			role: hasAccessTo(roles.user?.admin),
		},
		{
			path: routes.ADMIN_ACCOUNTING,
			element: <AdminAccounting />,
			role: hasAccessTo(roles.user?.admin),
		},
		{
			path: routes.ADMIN_USER_Detail.route,
			element: <AdminUserPageDetail />,
			role: hasAccessTo(roles.user?.admin),
		},
		{
			path: routes.ADMIN_ROLE,
			element: <AdminRolePageList />,
			role: hasAccessTo(roles.superAdmin),
		},
		{
			path: routes.ADMIN_USERS_ACTIVITY,
			element: <UsersActivityListPage />,
			role: hasAccessTo(roles.user?.admin),
		},
		{
			path: routes.ADMIN_USERS_HISTORY,
			element: <>user history</>,
			role: hasAccessTo(roles.user?.admin),
		},
		{
			path: routes.ADMIN_SETTINGS,
			element: <AdminSettingsListPage />,
			role: hasAccessTo(roles.superAdmin),
		},
		{
			path: routes.ADMIN_BONUS,
			element: <AdminBonusPage />,
			role: hasAccessTo(roles.superAdmin),
		},
		{
			path: routes.ADMIN_BALANCE_PROFIT,
			element: <AdminBalanceProfitPage />,
			role: hasAccessTo(roles.superAdmin),
		},
		{
			path: routes.ADMIN_WALLET_SETTINGS,
			element: <AdminWalletSettingsListPage />,
			role: hasAccessTo(roles.superAdmin),
		},
		{
			path: routes.ADMIN_INVOICE,
			element: <AdminInvoicePage />,
			role: hasAccessTo(roles.superAdmin),
		},
		{
			path: routes.ADMIN_PACKAGE,
			element: <AdminPackagePageList />,
			role: hasAccessTo(roles.package?.admin),
		},
		{
			path: routes.ADMIN_BANNERS,
			element: <AdminBannerPageList />,
			role: hasAccessTo(roles.package?.admin),
		},
		{
			path: routes.ADMIN_ORDER,
			element: <AdminOrderPageList />,
			role: hasAccessTo(roles.order?.admin),
		},
		{
			path: routes.ADMIN_USERS_TRANSACTIONS,
			element: <AdminUserTransactionPage />,
			role: hasAccessTo(roles.user?.admin),
		},
		{
			path: routes.ADMIN_PLANS_COMMISSIONS(),
			element: <AdminPlanCommissionsPageList />,
			role: hasAccessTo(roles.mlm?.admin),
		},
		{ path: routes.ACCOUNTING, element: <Accounting />, role: true },
		{ path: routes.WALLETS, element: <UserWalletsPage />, role: true },
		{ path: routes.TRANSACTION, element: <UserTransactionPage />, role: true },
		{
			path: routes.WALLETS_DEPOSIT_TRANSFER,
			element: <UserDepositWalletTransfer />,
			role: true,
		},
		{ path: routes.ORDER_SUBMIT_STEPS.route, element: <OrderSubmitPage />, role: true },
		{ path: routes.ORDERS_LIST, element: <UserActivePlansPageList />, role: true },
		{ path: routes.TRANSFER, element: <UserTransferPageList />, role: true },
		{ path: routes.ORDERS_HISTORY, element: <UserPlansHistoryPageList />, role: true },
		{
			path: routes.PLANS_COMMISSIONS(),
			element: <UserPlanCommissionsPageList />,
			role: true,
		},
		{ path: routes.WALLETS_DEPOSIT, element: <UserDepositPage />, role: true },
		{ path: routes.WALLETS_WITHDRAW, element: <UserWithdrawPage />, role: true },
		{
			path: routes.INTERNAL_TRANSFER,
			element: <UserInternalTransferPageList />,
			role: true,
		},
		{ path: routes.ADMIN_WITHDRAWS, element: <AdminWithdraws />, role: true },
		{ path: routes.USER_WITHDRAW_LIST, element: <UserWithdrawList />, role: true },
	];

	const publicRouteLists = [
		{ path: routes.REGISTER_SIGNING, element: <LoginRegisterPage /> },
	];

	if (userRolesIsFetching)
		return (
			<div className='w-full h-screen fixed flex justify-center items-center bg-[var(--dg-body-bg)]'>
				<ScaleLoader height={26} width={3} color='#ffc800' />{' '}
			</div>
		);

	return (
		<CrispProvider>
			<Suspense fallback={<GlobalLoading />}>
				{isAuthenticated ? (
					<PrivateLayout>
						<SocketProvider>
							<Routes>
								{privateRouteLists.map((route) => (
									<Route
										key={route.path}
										path={route.path}
										element={<PrivateRoute role={route.role} />}
									>
										<Route {...route} />
									</Route>
								))}

								<Route path='*' element={<Navigate to={routes.DASHBOARD} replace />} />
							</Routes>
						</SocketProvider>
					</PrivateLayout>
				) : (
					<PublicLayout>
						<Routes>
							{publicRouteLists.map((route) => (
								<Route key={route.path} {...route} />
							))}

							<Route
								path='*'
								element={<Navigate to={routes.REGISTER_SIGNING} replace />}
							/>
						</Routes>
					</PublicLayout>
				)}
			</Suspense>
		</CrispProvider>
	);
};

export default MainRouter;
