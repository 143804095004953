import { Tabs, TabsProps } from 'antd';
import { FC, ReactNode, useMemo } from 'react';

interface IDgTab extends TabsProps {
	activeTab: string | undefined;
	tabs: {
		key: string;
		label: ReactNode;
		children: ReactNode;
	}[];
	header?: ReactNode;
}

const DgTab: FC<IDgTab> = ({ tabs, activeTab, onChange, header, ...props }) => {
	//functions
	const handleTabChange = (key: string) => {
		if (onChange) onChange(key);
	};

	const items = useMemo(() => {
		return tabs.map((tab) => ({
			...tab,
			children: (
				<>
					{header ? header : null}
					{tab.children}
				</>
			),
		}));
	}, []);

	// console.log({ tabs, items, activeTab, header });

	return (
		<Tabs
			defaultActiveKey={activeTab}
			activeKey={activeTab}
			onChange={handleTabChange}
			items={items}
			destroyInactiveTabPane={true}
			// type='card'
			className='overflow-y-auto min-w-0 w-full'
			{...props}
		/>
	);
};

export default DgTab;
