import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import DgPagination from 'styles/UI/Pagination/DgPagination';
import DgTable from 'styles/UI/Table/DgTable';
import { useUserPlanCommissionsQuery } from '../hooks/react-query/useUserPlanCommissionsQuery';
import UserPlanCommissionsTableColumn from './UserPlanCommissionsTableColumn';
import { IPlanCommission } from 'core/types/models/planCommissionsType';
import { FC } from 'react';

interface IUserPlanCommissionsTable {
	limit?: number;
	scroll?: any;
}

const UserPlanCommissionsTable: FC<IUserPlanCommissionsTable> = ({ limit, ...props }) => {
	const { data: planCommissions, isFetching: planCommissionsIsFetching } =
		useUserPlanCommissionsQuery();
	const { query, handlePagination } = useQueryUrlParam();

	let data: IPlanCommission[] = [];
	if (limit && planCommissions?.data?.list) {
		data = planCommissions?.data?.list.slice(0, limit);
	}

	return (
		<>
			<DgTable
				rowKey='id'
				columns={UserPlanCommissionsTableColumn()}
				dataSource={data.length > 0 ? data : planCommissions?.data?.list}
				count={planCommissions?.data?.pagination?.total}
				loading={planCommissionsIsFetching}
				current={planCommissions?.data?.pagination?.current_page}
				per_page={query?.per_page}
				{...props}
			/>

			{!limit && (
				<DgPagination
					onPaginationHandler={handlePagination}
					responsive={true}
					per_page={query?.per_page}
					current={planCommissions?.data?.pagination?.current_page}
					total={planCommissions?.data?.pagination?.total}
				/>
			)}
		</>
	);
};

export default UserPlanCommissionsTable;
