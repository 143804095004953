import { useWindowSize } from 'core/hooks/useWindowSize';
import Text from 'core/utils/Text';
import DgTableColumnResponsive from 'styles/UI/TableColumnResponsive/DgTableColumnResponsive';
import DgPrice from 'styles/UI/Price/DgPrice';
import DgTimeStamp from 'styles/UI/Timstamp/DgTimeStamp';
import DgTooltip from 'styles/UI/Tooltip/DgTooltip';
import { IPlanCommission } from 'core/types/models/planCommissionsType';
import { convertTimeStampToDate, hiddenText } from 'core/utils/utils';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import useTranslate from 'core/utils/useTranslate';
import DgCopyClipboard from 'styles/UI/CopyClipboard/DgCopyClipboard';

const AdminPlanCommissionsTableColumn = () => {
	const { width } = useWindowSize();
	const { t } = useTranslate();

	const Columns = [
		{
			title: <Text tid='transaction-id' />,
			key: 'transaction_id',
			dataIndex: 'transaction_id',
			render: (text: any) => {
				const { value, hiddenValue } = hiddenText(text);
				return <DgCopyClipboard hiddenValue={hiddenValue} value={value} />;
			},
		},
		{
			title: <Text tid='amount' />,
			key: 'amount',
			render: (text: any) => '%' + text,
		},
		{
			title: <Text tid='type' />,
			key: 'type',
			dataIndex: 'type',
			render: (text: any) => <Text tid={text} />,
			sorter: false,
		},
		{
			title: <Text tid='duration' />,
			key: 'type',
			dataIndex: 'type',
			render: (text: any) =>
				text === 'invest-profit-commission' ||
				text === 'cooperation-profit-commission' ? (
					<Text tid='monthly' />
				) : text === 'marketing-profit-commission' ||
				  text === 'team-profit-commission' ? (
					<Text tid='weekly' />
				) : (
					<></>
				),
			sorter: false,
		},
		{
			title: <Text tid='invest-package' />,
			render: (_text: any, record: IPlanCommission) => {
				const { dateTime } = convertTimeStampToDate(
					record?.orderedPackage?.order?.canceled_at || 0
				);
				return (
					<DgTooltip
						overlayStyle={{ maxWidth: '300px' }}
						title={
							record?.orderedPackage?.order?.canceled_at
								? t('package-canceled') + `${dateTime}`
								: t('package-active')
						}
					>
						<span
							className={`whitespace-nowrap ${
								record?.orderedPackage?.order?.canceled_at
									? 'text-[var(--dg-red-color-2)]'
									: 'text-[var(--dg-green-color)]'
							}`}
						>
							{record?.orderedPackage?.order?.package.name}
						</span>
					</DgTooltip>
				);
			},
			sorter: false,
		},
		{
			title: <Text tid='date-time' />,
			render: (_text: any, record: IPlanCommission) => (
				<DgTimeStamp nowrap timeStamp={record.created_at} />
			),
			sorter: false,
		},
	];

	const RColumn = [
		{
			title: '',
			sorter: false,
			render: (_text: any, record: IPlanCommission) => {
				const { dateTime } = convertTimeStampToDate(
					record?.orderedPackage?.order?.canceled_at || 0
				);
				const { value, hiddenValue } = hiddenText(record.transaction_id + '');

				return (
					<>
						<DgTableColumnResponsive
							title={t('transaction-id')}
							value={<DgCopyClipboard hiddenValue={hiddenValue} value={value} />}
						/>
						<DgTableColumnResponsive
							title={t('amount')}
							value={<DgPrice price={record.amount} />}
						/>
						<DgTableColumnResponsive
							title={t('type')}
							value={<Text tid={record?.type} />}
						/>
						<DgTableColumnResponsive
							title={t('duration')}
							value={
								record?.type === 'invest-profit-commission' ||
								record?.type === 'cooperation-profit-commission' ? (
									<Text tid='monthly' />
								) : record?.type === 'marketing-profit-commission' ||
								  record?.type === 'team-profit-commission' ? (
									<Text tid='weekly' />
								) : (
									<></>
								)
							}
						/>

						<DgTableColumnResponsive
							title={t('invest-package')}
							value={
								<span
									className={`flex items-center gap-3 justify-end ${
										record?.orderedPackage?.order?.canceled_at
											? 'text-[var(--dg-red-color-2)]'
											: 'text-[var(--dg-green-color)]'
									}`}
								>
									<DgTooltip
										overlayStyle={{ maxWidth: '300px' }}
										title={
											record?.orderedPackage?.order?.canceled_at
												? t('package-canceled') + `${dateTime}`
												: t('package-active')
										}
									>
										<AiOutlineInfoCircle size={20} />
									</DgTooltip>
									{record?.orderedPackage?.order?.package.name}
								</span>
							}
						/>

						<DgTableColumnResponsive
							title={t('date-time')}
							value={<DgTimeStamp nowrap timeStamp={record.created_at} />}
						/>
					</>
				);
			},
		},
	];

	return width > 769 ? Columns : RColumn;
};

export default AdminPlanCommissionsTableColumn;
