/* eslint-disable no-undef */
import { useEffect } from 'react';
import i18next from 'i18next';
import { ConfigProvider } from 'antd';
import fa from 'antd/es/locale/fa_IR';
import en from 'antd/es/locale/en_US';
import MainRouter from './MainRouter';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

const Index = () => {
	const { lang, theme } = useSelector((store: RootState) => store.settingStore);

	useEffect(() => {
		i18next.changeLanguage(lang);
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		const root = document.querySelector(':root');
		if (root) {
			root.setAttribute('color-scheme', theme);
			theme === 'dark' && root.classList.add('dark');
			theme === 'light' && root.classList.remove('dark');

			lang === 'en' && root.setAttribute('dir', 'ltr');
			lang === 'fa' && root.setAttribute('dir', 'rtl');
		}
	}, [theme, lang]);

	return (
		<ConfigProvider input={{ autoComplete: 'off' }} locale={lang === 'en' ? en : fa} direction={lang === 'en' ? 'ltr' : 'rtl'}>
			<MainRouter />
		</ConfigProvider>
	);
};

export default Index;
