import { UseQueryResult } from '@tanstack/react-query';
import { endPointUrls } from 'core/constants/endPointUrls';
import api from 'core/services/fetch-api/useApi';
import { userList } from 'core/types/models/userInfo';
import useQueryUrlParam from 'core/hooks/useQueryUrlParam';
import useQueryHook from 'core/hooks/masterQuery/useQueryHook';

export const useAdminUserQuery = () => {
	const { filterParams } = useQueryUrlParam();
	console.log('hiiiiiiiiii', filterParams);
	return useQueryHook(
		[endPointUrls.ADMIN_USERS(), filterParams],
		(data: any) => api.get(endPointUrls.ADMIN_USERS(filterParams), data),
		{
			select: (res: any) => res.data,
			keepPreviousData: true,
		}
	) as UseQueryResult<userList, Error>;
};

export const useAdminDeactivatedUserQuery = () => {
	const { filterParams } = useQueryUrlParam();
	// console.log('hiiiiiiiiii', filterParams);
	return useQueryHook(
		[endPointUrls.ADMIN_DEACTIVATED_USERS(), filterParams],
		(data: any) => api.get(endPointUrls.ADMIN_DEACTIVATED_USERS(filterParams), data),
		{
			select: (res: any) => res.data,
			keepPreviousData: true,
		}
	) as UseQueryResult<userList, Error>;
};
