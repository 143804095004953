import { createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';

const initialState: { theme: 'dark' | 'light'; lang: 'fa' | 'en'; isEnglish: boolean } = {
	theme: (window.localStorage.getItem('theme') as 'dark' | 'light') || 'dark',
	lang: (window.localStorage.getItem('lang') as 'fa' | 'en') || 'en',
	isEnglish: window.localStorage.getItem('lang') === 'en' ? true : false,
};

export const mainInfoSlice = createSlice({
	name: 'mainInfo',
	initialState: initialState,
	reducers: {
		setTheme: (state, { payload }) => {
			state.theme = payload;
			window.localStorage.setItem('theme', payload);
		},
		setLang: (state, { payload }) => {
			state.lang = payload;
			state.isEnglish = payload === 'en' ? true : false;
			i18next.changeLanguage(payload);
			window.localStorage.setItem('lang', payload);
		},
	},
});

export const { setTheme, setLang } = mainInfoSlice.actions;
export default mainInfoSlice.reducer;
