import { UseMutationResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { endPointUrls } from 'core/constants/endPointUrls';
import toastHandler from 'core/helpers/toast/toast';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import api from 'core/services/fetch-api/useApi';
import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';
import { ISubmitOrderMutationResponse } from 'core/types/models/submitOrderType';
import { useDispatch } from 'react-redux';

export const useOrderSubmitQuery = (callBack?: () => void) => {
	const dispatch = useDispatch();

	return useMutationHook(
		[endPointUrls.ORDER_SUBMIT],
		(data: any) => api.post(endPointUrls.ORDER_SUBMIT, data),
		{
			onSuccess: (res) => {
				const response = res as AxiosResponse;

				if (response?.status === 200) {
					toastHandler('success', response?.data.message);
					callBack?.();
					dispatch(setApiCatcherFormErrors([]));
				}
			},
		}
	) as UseMutationResult<ISubmitOrderMutationResponse>;
};
