import React, { FC } from 'react';
import DgTooltip from '../Tooltip/DgTooltip';
import { Link } from 'react-router-dom';
import { MdOutlineEditNote } from 'react-icons/md';

interface IDgEditIcon {
	to?: string | number;
	onClick?: () => void;
	className?: string;
}

const DgEditIcon: FC<IDgEditIcon> = ({ onClick, to, className }) => {
	return (
		<DgTooltip title='edit'>
			{to ? (
				<Link to={to + ''} className={className}>
					<MdOutlineEditNote size={25} />
				</Link>
			) : (
				<MdOutlineEditNote
					size={25}
					className={`cursor-pointer ${className}`}
					onClick={onClick}
				/>
			)}
		</DgTooltip>
	);
};

export default DgEditIcon;
