import { UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { endPointUrls } from 'core/constants/endPointUrls';
import toastHandler from 'core/helpers/toast/toast';
import useMutationHook from 'core/hooks/masterQuery/useMutationHook';
import api from 'core/services/fetch-api/useApi';
import { setApiCatcherFormErrors } from 'core/store/slice/mainInfo/mainInfoSlice';
import { useDispatch } from 'react-redux';

export const useBannersMutation = (callBack?: () => void) => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	return useMutationHook(
		[endPointUrls.SET_BANNERS],
		(data: any) => {
			const formData = new FormData();
			formData.append('image', data.image);
			formData.append('banner', data.banner);
			formData.append('link', data.link);

			// console.log({ data, formData });

			return api.post(endPointUrls.SET_BANNERS, formData);
		},
		{
			onSuccess: (res) => {
				const response = res as AxiosResponse;

				if (response?.status === 200) {
					queryClient.invalidateQueries([endPointUrls.SET_BANNERS]);
					toastHandler('success', response.data.message);
					dispatch(setApiCatcherFormErrors([]));
					callBack?.();
				}
			},
		}
	) as UseMutationResult;
};
