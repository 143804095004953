import useQueryUrlParam from '../../../core/hooks/useQueryUrlParam';
import { useAdminWithdraws } from './useAdminWithdraws';
import DgTable from '../../../styles/UI/Table/DgTable';
import WithdrawTableColumns from './WithdrawTableColumns';
import DgPagination from '../../../styles/UI/Pagination/DgPagination';

const WithdrawsTable = () => {
	const { data: withdraws, isFetching } = useAdminWithdraws();
	const { query, handlePagination } = useQueryUrlParam();

	return (
		<>
			<DgTable
				rowKey='transaction_id'
				columns={WithdrawTableColumns()}
				dataSource={withdraws?.data?.list}
				count={withdraws?.data?.pagination?.total}
				loading={isFetching}
				current={withdraws?.data?.pagination?.current_page}
				per_page={query?.per_page}
			/>

			<DgPagination
				onPaginationHandler={handlePagination}
				responsive={true}
				per_page={query?.per_page}
				current={withdraws?.data?.pagination?.current_page}
				total={withdraws?.data?.pagination?.total}
			/>
		</>
	);
};

export default WithdrawsTable;
